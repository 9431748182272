import LAYOUT_CONST from "constant";

export const JWT_API = {
  secret: "$movilaes2023_sc",
  timeout: "1 days",
};

export const FIREBASE_API = {
  apiKey: "AIzaSyAIBsCgLTU3OiUqq8wJYCl09bTFP4IHobo",
  authDomain: "aes-app-755d2.firebaseapp.com",
  databaseURL: "https://aes-app-755d2.firebaseio.com",
  projectId: "aes-app-755d2",
  storageBucket: "aes-app-755d2.appspot.com",
  messagingSenderId: "661443106335",
  appId: "1:661443106335:web:2cfbb0cb41adeeaaa8272a",
  measurementId: "G-YC65FJ8H04",
};


export const BASE_PATH = "";










// export const DASHBOARD_PATH = '/dashboard/default';
export const DASHBOARD_PATH = "/inicio";
export const DASHBOARD_PATH_INITIATED = "/inicio";
export const DASHBOARD_PRIVATE_INITIATED = "/internal";
export const GMAPS_KEY = "AIzaSyBFyNOHwr-g49e5QiGtpZ7zrGLdxRh76sA";


// export const API_URL = "https://app.movilaesweb.com/";
// export const API_URL = "https://app.movilaeswebdes.com/";
// export const API_URL = "http://127.0.0.1:8180/";
// DES SAP
// export const API_URL = "https://app.movilaeswebdes.com/";
// PRD SAP
export const API_URL = "https://customerapi.movilaesweb.com/";

// export const API_URL = "http://localhost:8180/";
// DES SAP
// export const API_URL_CJ = "https://customerjourney.movilaeswebdes.com/";
// PRD SAP
export const API_URL_CJ = "https://customerjourney.movilaesweb.com/";
// export const API_URL_CJ = "https://customerjourney.movilaesweb.com/";

// export const API_URL_SGC ="https://www.aeselsalvadormovil.com:8443/WCFMovilPR/AESMovil.svc/";
// export const API_URL_SGC ="https://www.aeselsalvadormovil.com:8443/WfcMovil_QA/AESMovil.svc/";
// DES SAP
// export const API_URL_SGC ="https://app.movilaeswebdes.com/legacy/";
// PRD SAP
export const API_URL_SGC ="https://customerapi.movilaesweb.com/legacy/";

export const HORIZONTAL_MAX_ITEM = 6;



const timeOfDay = () => { //disabled 
  const now = new Date();
  const hour = now.getHours();
  const isDayTime = hour >= 6 && hour < 18;
  return isDayTime ? "light" : "light";
};

const paletteMode = timeOfDay();
export const env = "";
// export const env = "DES_";
export const ACFServerMessages = (s) => {
  if (s.indexOf("Missing or invalid service ID") > -1) {
    return "Error técnico";
  }
  if (
    s.indexOf("No active calendar exists in the specified service and date") >
    -1
  ) {
    return "No hay cupos disponibles para la fecha seleccionada";
  }
  if (s.indexOf("The calendar is full") > -1) {
    return "No hay cupos disponibles para la fecha seleccionada";
  }
  if (s.indexOf("Invalid or missing customer ID") > -1) {
    return "No se ha encontrado el ID del usuario, por favor inicie sesión";
  }
  if (
    s.indexOf(
      "No slot was found for the specified time or the slot is already booked"
    ) > -1
  ) {
    return "El cupo seleccionado ya fue reservado. Intente nuevamente";
  }
  if (
    s.indexOf("Not enough vacant slots to accommodate the appointment") > -1
  ) {
    return "El cupo seleccionado ya fue reservado. Intente nuevamente";
  }
  if (s.indexOf("same day") > -1) {
    return "Ya posee una cita para el mismo servicio en el mismo día.";
  }
  if (s.indexOf("same day") > -1) {
    return "Ya posee una cita para otro servicio en el mismo día.";
  }
  if (
    s ===
    "Warning: The requested duration is longer than the required for this appointment type"
  ) {
    return "Error técnico. 1.00";
  }
  if (s.indexOf("The requested appointment date is in the past") > -1) {
    return "La fecha/hora seleccionada ya no está disponible.";
  }
  if (s.indexOf("The specified time slot is reserved") > -1) {
    return "El cupo seleccionado ya fue reservado. Intente nuevamente";
  }
  if (
    s.indexOf("Customer group cannot be used in the specified service") > -1
  ) {
    return "Error técnico. 1.01";
  }
  if (s.indexOf("Could not acquire lock on the requested calendar") > -1) {
    return "El cupo seleccionado ya fue reservado. Intente nuevamente.";
  }
  if (
    s ===
    "Not enough vacant time in the required time segment to accommodate the appointment"
  ) {
    return "El cupo seleccionado ya fue reservado. Intente nuevamente";
  }
  if (s.indexOf("The specified service requires an appointment type") > -1) {
    return "El cupo seleccionado ya fue reservado. Intente nuevamente";
  }
  if (
    s ===
    "The specified appointment type is not available on the specified time"
  ) {
    return "El cupo seleccionado ya fue reservado. Intente nuevamente";
  }
  if (s.indexOf("The specified service is Inactive") > -1) {
    return "El cupo seleccionado ya fue reservado. Intente nuevamente";
  }
  if (
    s ===
    "Simulation completed successfully. The transaction was not committed."
  ) {
    return "El cupo seleccionado ya fue reservado. Intente nuevamente";
  }
  if (
    s ===
    "You don’t have permissions to schedule an appointment of the selected type."
  ) {
    return "El cupo seleccionado ya fue reservado. Intente nuevamente";
  }
  if (s.indexOf("Invalid user id") > -1) {
    return "El cupo seleccionado ya fue reservado. Intente nuevamente";
  }
  if (s.indexOf("The Service is not licensed and can not be used") > -1) {
    return "El cupo seleccionado ya fue reservado. Intente nuevamente";
  }
  if (
    s ===
    "Appointment has to be classified according to service profile setting"
  ) {
    return "El cupo seleccionado ya fue reservado. Intente nuevamente";
  }
  if (s.indexOf("The specified appointment was already queued") > -1) {
    return "El cupo seleccionado ya fue colocado en la cola";
  }

  // If none of the above conditions match, return the original string.
  return s;
};

export const empresas = [
  {
    value: "1",
    label: "CAESS",
  },
  {
    value: "2",
    label: "CLESA",
  },
  {
    value: "3",
    label: "EEO",
  },
  {
    value: "4",
    label: "DEUSEM",
  },
];

export const tiposEntidad = [
  {
    value: "Personas",
    label: "Persona",
  },
  {
    value: "Empresa",
    label: "Empresa",
  },
]
export const tiposDocumentoOPEN = [
	{
        value: "TD008",
        label: "Documento único",
      },
      {
        value: "TD003",
        label: "Carnet residencial",
      },
      {
        value: "TD002",
        label: "Pasaporte",
      },
]
export const tiposDocumento = [
  {
    value: "1",
    label: "DUI",
  },
  {
    value: "2",
    label: "NIT",
  },  
  {
    value: "4",
    label: "Pasaporte",
  },
  {
    value: "5",
    label: "Carnet de residente",
  },
];



export const tiposDocumentoCitas = [
	{
	  value: "1",
	  label: "DUI",
	},
	{
	  value: "2",
	  label: "NIT",
	},  
	{
		value: "3",
		label: "NIC",
	  },  
	{
	  value: "4",
	  label: "Pasaporte",
	},
	{
	  value: "5",
	  label: "Carnet de residente",
	},
  ];
// Se retira NIC a petición de KMorales (AES) 09/Oct/2023
// {
//     value: "5",
//     label: "NIC",
//   },

export const motivos = [
  {
    value: "1",
    label: "Línea directa",
  },
  {
    value: "2",
    label: "Medidor dañado",
  },
  {
    value: "3",
    label: "Medidor con suciedad o quebrado",
  },
  {
    value: "4",
    label: "Servicio conectado directo",
  },
  {
    value: "5",
    label: "Soldador conectado",
  },
  {
    value: "6",
    label: "Otros",
  },
  {
    value: "7",
    label: "Manipulación de Medidor",
  },
];
export const horarios = [
  {
    value: "1",
    label: "Mañana",
  },
  {
    value: "2",
    label: "Tarde",
  },
  {
    value: "3",
    label: "Noche",
  },
  {
    value: "4",
    label: "Permanente",
  },
];
export const departamentos = [
  {
    value: "1",
    label: "Ahuachapan",
  },
  {
    value: "2",
    label: "Sonsonate",
  },
  {
    value: "3",
    label: "Santa Ana",
  },
  {
    value: "4",
    label: "San Salvador",
  },
  {
    value: "5",
    label: "La Libertad",
  },
  {
    value: "6",
    label: "Cuscatlán",
  },
  {
    value: "7",
    label: "San Vicente",
  },
  {
    value: "8",
    label: "San Miguel",
  },
  {
    value: "9",
    label: "Morazán",
  },
  {
    value: "10",
    label: "Usulután",
  },
  {
    value: "11",
    label: "La Unión",
  },
  {
    value: "12",
    label: "Chalatenango",
  },
  {
    value: "13",
    label: "Cabañas",
  },
];
export const peligros = [
  {
    value: "1",
    label: "Daño en el transformador",
  },
  {
    value: "2",
    label: "Lineas en el suelo",
  },
  {
    value: "3",
    label: "Variaciones de voltaje",
  },
  {
    value: "4",
    label: "Daños retenida",
  },
  {
    value: "5",
    label: "Daños en acometida",
  },
  {
    value: "6",
    label: "Línea rota",
  },
  {
    value: "7",
    label: "Poste chocado",
  },
  {
    value: "8",
    label: "Conato de incendio",
  },
  {
    value: "9",
    label: "Líneas eléctricas bajas",
  },
  {
    value: "10",
    label: "Líneas cortocircuitadas",
  },
  {
    value: "11",
    label: "Falso contacto",
  },
  {
    value: "12",
    label: "Poste desplomado",
  },
  {
    value: "13",
    label: "Poste lavado de la base",
  },
  {
    value: "14",
    label: "Fallas en alumbrado público",
  },
];
export const tiposServicio = [
  {
    value: "TC090",
    label: "Residencial",
  },
  {
    value: "TC093",
    label: "Comercial",
  },
]

export const tiposConexion = [
  {
    value: "CX110",
    label: "120V",
  },
  {
    value: "CX111",
    label: "120/240V",
  },
  {
    value: "CX112",
    label: "120/208V",
  },
]

export const profesionesLst = [
  {
		"ID" : 1,
		"value" : "110",
		"label" : "Oficiales de las fuerzas armadas"
	},
	{
		"ID" : 2,
		"value" : "210",
		"label" : "Suboficiales de las fuerzas armadas"
	},
	{
		"ID" : 3,
		"value" : "310",
		"label" : "Otros miembros de las fuerzas armadas"
	},
	{
		"ID" : 4,
		"value" : "410",
		"label" : "Licenciado"
	},
	{
		"ID" : 5,
		"value" : "1111",
		"label" : "Miembros del poder legislativo"
	},
	{
		"ID" : 6,
		"value" : "1112",
		"label" : "Personal directivo de la administración pública"
	},
	{
		"ID" : 7,
		"value" : "1113",
		"label" : "Jefes de pequeñas poblaciones"
	},
	{
		"ID" : 8,
		"value" : "1114",
		"label" : "Dirigentes de organizaciones que presentan un interés especial"
	},
	{
		"ID" : 9,
		"value" : "1120",
		"label" : "Directores generales y gerentes generales"
	},
	{
		"ID" : 10,
		"value" : "1211",
		"label" : "Directores financieros"
	},
	{
		"ID" : 11,
		"value" : "1212",
		"label" : "Directores de recursos humanos"
	},
	{
		"ID" : 12,
		"value" : "1213",
		"label" : "Directores de políticas y planificación"
	},
	{
		"ID" : 13,
		"value" : "1219",
		"label" : "Directores de administración y servicios no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 14,
		"value" : "1221",
		"label" : "Directores de ventas y comercialización"
	},
	{
		"ID" : 15,
		"value" : "1222",
		"label" : "Directores de publicidad y relaciones públicas"
	},
	{
		"ID" : 16,
		"value" : "1223",
		"label" : "Directores de investigación y desarrollo"
	},
	{
		"ID" : 17,
		"value" : "1311",
		"label" : "Directores de producción agropecuaria y silvicultura"
	},
	{
		"ID" : 18,
		"value" : "1312",
		"label" : "Directores de producción de piscicultura y pesca"
	},
	{
		"ID" : 19,
		"value" : "1321",
		"label" : "Directores de industrias manufactureras"
	},
	{
		"ID" : 20,
		"value" : "1322",
		"label" : "Directores de explotaciones de minería"
	},
	{
		"ID" : 21,
		"value" : "1323",
		"label" : "Directores de empresas de construcción"
	},
	{
		"ID" : 22,
		"value" : "1324",
		"label" : "Directores de empresas de abastecimiento, distribución y afines"
	},
	{
		"ID" : 23,
		"value" : "1330",
		"label" : "Directores de servicios de tecnología de la información y las comunicaciones"
	},
	{
		"ID" : 24,
		"value" : "1341",
		"label" : "Directores de servicios de cuidados infantiles"
	},
	{
		"ID" : 25,
		"value" : "1342",
		"label" : "Directores de servicios de salud"
	},
	{
		"ID" : 26,
		"value" : "1343",
		"label" : "Directores de servicios de cuidado de las personas de edad"
	},
	{
		"ID" : 27,
		"value" : "1344",
		"label" : "Directores  de servicios de bienestar social"
	},
	{
		"ID" : 28,
		"value" : "1345",
		"label" : "Directores de servicios de educación"
	},
	{
		"ID" : 29,
		"value" : "1346",
		"label" : "Gerentes de sucursales de bancos, de servicios financieros y de seguros"
	},
	{
		"ID" : 30,
		"value" : "1349",
		"label" : "Directores y gerentes de servicios profesionales no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 31,
		"value" : "1411",
		"label" : "Gerentes de hoteles"
	},
	{
		"ID" : 32,
		"value" : "1412",
		"label" : "Gerentes de restaurantes"
	},
	{
		"ID" : 33,
		"value" : "1420",
		"label" : "Gerentes de comercios al por mayor y al por menor"
	},
	{
		"ID" : 34,
		"value" : "1431",
		"label" : "Gerentes de centros deportivos, de esparcimiento y culturales"
	},
	{
		"ID" : 35,
		"value" : "1439",
		"label" : "Gerentes de servicios no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 36,
		"value" : "2111",
		"label" : "Físicos y astrónomos"
	},
	{
		"ID" : 37,
		"value" : "2112",
		"label" : "Meteorólogos"
	},
	{
		"ID" : 38,
		"value" : "2113",
		"label" : "Químicos"
	},
	{
		"ID" : 39,
		"value" : "2114",
		"label" : "Geólogos y geofísicos"
	},
	{
		"ID" : 40,
		"value" : "2120",
		"label" : "Matemáticos, actuarios y estadísticos"
	},
	{
		"ID" : 41,
		"value" : "2131",
		"label" : "Biólogos, botánicos, zoólogos y afines"
	},
	{
		"ID" : 42,
		"value" : "2132",
		"label" : "Agrónomos y afines"
	},
	{
		"ID" : 43,
		"value" : "2133",
		"label" : "Profesionales de la protección medioambiental"
	},
	{
		"ID" : 44,
		"value" : "2141",
		"label" : "Ingenieros industriales y de producción"
	},
	{
		"ID" : 45,
		"value" : "2142",
		"label" : "Ingenieros civiles"
	},
	{
		"ID" : 46,
		"value" : "2143",
		"label" : "Ingenieros medioambientales"
	},
	{
		"ID" : 47,
		"value" : "2144",
		"label" : "Ingenieros mecánicos"
	},
	{
		"ID" : 48,
		"value" : "2145",
		"label" : "Ingenieros químicos"
	},
	{
		"ID" : 49,
		"value" : "2146",
		"label" : "Ingenieros de minas, metalúrgicos y afines"
	},
	{
		"ID" : 50,
		"value" : "2149",
		"label" : "Ingenieros no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 51,
		"value" : "2151",
		"label" : "Ingenieros electricistas"
	},
	{
		"ID" : 52,
		"value" : "2152",
		"label" : "Ingenieros electrónicos"
	},
	{
		"ID" : 53,
		"value" : "2153",
		"label" : "Ingenieros en telecomunicaciones"
	},
	{
		"ID" : 54,
		"value" : "2154",
		"label" : "Ingeniero en Sistemas y\/o Computación"
	},
	{
		"ID" : 55,
		"value" : "2161",
		"label" : "Arquitectos"
	},
	{
		"ID" : 56,
		"value" : "2162",
		"label" : "Arquitectos paisajistas"
	},
	{
		"ID" : 57,
		"value" : "2163",
		"label" : "Diseñadores de productos y de prendas"
	},
	{
		"ID" : 58,
		"value" : "2164",
		"label" : "Urbanistas e ingenieros de tránsito"
	},
	{
		"ID" : 59,
		"value" : "2165",
		"label" : "Cartógrafos y agrimensores"
	},
	{
		"ID" : 60,
		"value" : "2166",
		"label" : "Diseñadores gráficos y multimedia"
	},
	{
		"ID" : 61,
		"value" : "2211",
		"label" : "Médicos generales"
	},
	{
		"ID" : 62,
		"value" : "2212",
		"label" : "Médicos especialistas "
	},
	{
		"ID" : 63,
		"value" : "2221",
		"label" : "Profesionales de enfermería"
	},
	{
		"ID" : 64,
		"value" : "2222",
		"label" : "Profesionales de partería"
	},
	{
		"ID" : 65,
		"value" : "2230",
		"label" : "Profesionales de medicina tradicional y alternativa"
	},
	{
		"ID" : 66,
		"value" : "2240",
		"label" : "Practicantes paramédicos"
	},
	{
		"ID" : 67,
		"value" : "2250",
		"label" : "Veterinarios"
	},
	{
		"ID" : 68,
		"value" : "2261",
		"label" : "Dentistas"
	},
	{
		"ID" : 69,
		"value" : "2262",
		"label" : "Farmacéuticos"
	},
	{
		"ID" : 70,
		"value" : "2263",
		"label" : "Profesionales de la salud y la higiene laboral y ambiental"
	},
	{
		"ID" : 71,
		"value" : "2264",
		"label" : "Fisioterapeutas "
	},
	{
		"ID" : 72,
		"value" : "2265",
		"label" : "Dietistas y nutricionistas"
	},
	{
		"ID" : 73,
		"value" : "2266",
		"label" : "Audiólogos y logopedas"
	},
	{
		"ID" : 74,
		"value" : "2267",
		"label" : "Optometristas"
	},
	{
		"ID" : 75,
		"value" : "2269",
		"label" : "Profesionales de la salud no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 76,
		"value" : "2310",
		"label" : "Profesores de universidades y de la enseñanza superior"
	},
	{
		"ID" : 77,
		"value" : "2320",
		"label" : "Profesores de formación profesional"
	},
	{
		"ID" : 78,
		"value" : "2330",
		"label" : "Profesores de enseñanza secundaria"
	},
	{
		"ID" : 79,
		"value" : "2341",
		"label" : "Maestros de enseñanza primaria"
	},
	{
		"ID" : 80,
		"value" : "2342",
		"label" : "Maestros preescolares"
	},
	{
		"ID" : 81,
		"value" : "2351",
		"label" : "Especialistas en métodos pedagógicos"
	},
	{
		"ID" : 82,
		"value" : "2352",
		"label" : "Educadores para necesidades especiales"
	},
	{
		"ID" : 83,
		"value" : "2353",
		"label" : "Otros profesores de idiomas"
	},
	{
		"ID" : 84,
		"value" : "2354",
		"label" : "Otros profesores de música"
	},
	{
		"ID" : 85,
		"value" : "2355",
		"label" : "Otros profesores de artes"
	},
	{
		"ID" : 86,
		"value" : "2356",
		"label" : "Instructores en tecnología de la información"
	},
	{
		"ID" : 87,
		"value" : "2359",
		"label" : "Profesionales de la enseñanza no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 88,
		"value" : "2411",
		"label" : "Contables"
	},
	{
		"ID" : 89,
		"value" : "2412",
		"label" : "Asesores financieros y en inversiones"
	},
	{
		"ID" : 90,
		"value" : "2413",
		"label" : "Analistas financieros"
	},
	{
		"ID" : 91,
		"value" : "2421",
		"label" : "Analistas de gestión y organización"
	},
	{
		"ID" : 92,
		"value" : "2422",
		"label" : "Especialistas en políticas de administración"
	},
	{
		"ID" : 93,
		"value" : "2423",
		"label" : "Especialistas en políticas y servicios de personal y afines"
	},
	{
		"ID" : 94,
		"value" : "2424",
		"label" : "Especialistas en formación del personal"
	},
	{
		"ID" : 95,
		"value" : "2431",
		"label" : "Profesionales de la publicidad y la comercialización"
	},
	{
		"ID" : 96,
		"value" : "2432",
		"label" : "Profesionales de relaciones públicas"
	},
	{
		"ID" : 97,
		"value" : "2433",
		"label" : "Profesionales de ventas técnicas y médicas (excluyendo la TIC)"
	},
	{
		"ID" : 98,
		"value" : "2434",
		"label" : "Profesionales de ventas de tecnología de la información y las comunicaciones"
	},
	{
		"ID" : 99,
		"value" : "2511",
		"label" : "Analistas de sistemas"
	},
	{
		"ID" : 100,
		"value" : "2512",
		"label" : "Desarrolladores de software"
	},
	{
		"ID" : 101,
		"value" : "2513",
		"label" : "Desarrolladores Web y multimedia"
	},
	{
		"ID" : 102,
		"value" : "2514",
		"label" : "Programadores de aplicaciones"
	},
	{
		"ID" : 103,
		"value" : "2519",
		"label" : "Desarrolladores y analistas de software y multimedia y analistas no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 104,
		"value" : "2521",
		"label" : "Diseñadores y administradores de bases de datos"
	},
	{
		"ID" : 105,
		"value" : "2522",
		"label" : "Administradores de sistemas"
	},
	{
		"ID" : 106,
		"value" : "2523",
		"label" : "Profesionales en redes de computadores"
	},
	{
		"ID" : 107,
		"value" : "2529",
		"label" : "Especialistas en bases de datos y en redes de computadores no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 108,
		"value" : "2611",
		"label" : "Abogados"
	},
	{
		"ID" : 109,
		"value" : "2612",
		"label" : "Jueces"
	},
	{
		"ID" : 110,
		"value" : "2619",
		"label" : "Profesionales en derecho no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 111,
		"value" : "2621",
		"label" : "Archivistas y curadores de museos"
	},
	{
		"ID" : 112,
		"value" : "2622",
		"label" : "Bibliotecarios, documentalistas y afines"
	},
	{
		"ID" : 113,
		"value" : "2631",
		"label" : "Economistas"
	},
	{
		"ID" : 114,
		"value" : "2632",
		"label" : "Sociólogos, antropólogos y afines"
	},
	{
		"ID" : 115,
		"value" : "2633",
		"label" : "Filósofos, historiadores y especialistas en ciencias políticas"
	},
	{
		"ID" : 116,
		"value" : "2634",
		"label" : "Psicólogos"
	},
	{
		"ID" : 117,
		"value" : "2635",
		"label" : "Profesionales del trabajo social"
	},
	{
		"ID" : 118,
		"value" : "2636",
		"label" : "Profesionales religiosos"
	},
	{
		"ID" : 119,
		"value" : "2641",
		"label" : "Autores y otros escritores"
	},
	{
		"ID" : 120,
		"value" : "2642",
		"label" : "Periodistas"
	},
	{
		"ID" : 121,
		"value" : "2643",
		"label" : "Traductores, intérpretes y lingüistas"
	},
	{
		"ID" : 122,
		"value" : "2651",
		"label" : "Artistas de artes plásticas"
	},
	{
		"ID" : 123,
		"value" : "2652",
		"label" : "Músicos, cantantes y compositores"
	},
	{
		"ID" : 124,
		"value" : "2653",
		"label" : "Bailarines y coreógrafos"
	},
	{
		"ID" : 125,
		"value" : "2654",
		"label" : "Directores de cine, de teatro y afines"
	},
	{
		"ID" : 126,
		"value" : "2655",
		"label" : "Actores"
	},
	{
		"ID" : 127,
		"value" : "2656",
		"label" : "Locutores de radio, televisión y otros medios de comunicación"
	},
	{
		"ID" : 128,
		"value" : "2659",
		"label" : "Artistas creativos e interpretativos no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 129,
		"value" : "3111",
		"label" : "Técnicos en ciencias físicas y químicas"
	},
	{
		"ID" : 130,
		"value" : "3112",
		"label" : "Técnicos en ingeniería civil"
	},
	{
		"ID" : 131,
		"value" : "3113",
		"label" : "Electrotécnicos"
	},
	{
		"ID" : 132,
		"value" : "3114",
		"label" : "Técnicos en electrónica"
	},
	{
		"ID" : 133,
		"value" : "3115",
		"label" : "Técnicos en ingeniería mecánica"
	},
	{
		"ID" : 134,
		"value" : "3116",
		"label" : "Técnicos en química industrial"
	},
	{
		"ID" : 135,
		"value" : "3117",
		"label" : "Técnicos en ingeniería de minas y metalurgia"
	},
	{
		"ID" : 136,
		"value" : "3118",
		"label" : "Delineantes y dibujantes técnicos"
	},
	{
		"ID" : 137,
		"value" : "3119",
		"label" : "Técnicos en ciencias físicas y en ingeniería no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 138,
		"value" : "3121",
		"label" : "Supervisores en ingeniería de minas"
	},
	{
		"ID" : 139,
		"value" : "3122",
		"label" : "Supervisores de industrias manufactureras"
	},
	{
		"ID" : 140,
		"value" : "3123",
		"label" : "Supervisores de la construcción"
	},
	{
		"ID" : 141,
		"value" : "3131",
		"label" : "Operadores de instalaciones de producción de energía"
	},
	{
		"ID" : 142,
		"value" : "3132",
		"label" : "Operadores de incineradores, instalaciones de tratamiento de agua y afines"
	},
	{
		"ID" : 143,
		"value" : "3133",
		"label" : "Controladores de instalaciones de procesamiento de productos químicos"
	},
	{
		"ID" : 144,
		"value" : "3134",
		"label" : "Operadores de instalaciones de refinación de petróleo y gas natural"
	},
	{
		"ID" : 145,
		"value" : "3135",
		"label" : "Controladores de procesos de producción de metales"
	},
	{
		"ID" : 146,
		"value" : "3139",
		"label" : "Técnicos en control de procesos no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 147,
		"value" : "3141",
		"label" : "Técnicos en ciencias biológicas (excluyendo la medicina)"
	},
	{
		"ID" : 148,
		"value" : "3142",
		"label" : "Técnicos agropecuarios"
	},
	{
		"ID" : 149,
		"value" : "3143",
		"label" : "Técnicos forestales"
	},
	{
		"ID" : 150,
		"value" : "3151",
		"label" : "Oficiales maquinistas en navegación"
	},
	{
		"ID" : 151,
		"value" : "3152",
		"label" : "Capitanes, oficiales de cubierta y prácticos"
	},
	{
		"ID" : 152,
		"value" : "3153",
		"label" : "Pilotos de aviación y afines"
	},
	{
		"ID" : 153,
		"value" : "3154",
		"label" : "Controladores de tráfico aéreo"
	},
	{
		"ID" : 154,
		"value" : "3155",
		"label" : "Técnicos en seguridad aeronáutica"
	},
	{
		"ID" : 155,
		"value" : "3211",
		"label" : "Técnicos en aparatos de diagnóstico y tratamiento médico"
	},
	{
		"ID" : 156,
		"value" : "3212",
		"label" : "Técnicos de laboratorios médicos"
	},
	{
		"ID" : 157,
		"value" : "3213",
		"label" : "Técnicos y asistentes farmacéuticos"
	},
	{
		"ID" : 158,
		"value" : "3214",
		"label" : "Técnicos de prótesis médicas y dentales"
	},
	{
		"ID" : 159,
		"value" : "3221",
		"label" : "Profesionales de nivel medio de enfermería"
	},
	{
		"ID" : 160,
		"value" : "3222",
		"label" : "Profesionales de nivel medio de partería"
	},
	{
		"ID" : 161,
		"value" : "3230",
		"label" : "Profesionales de nivel medio de medicina tradicional y alternativa"
	},
	{
		"ID" : 162,
		"value" : "3240",
		"label" : "Técnicos y asistentes veterinarios"
	},
	{
		"ID" : 163,
		"value" : "3251",
		"label" : "Dentistas auxiliares y ayudantes de odontología"
	},
	{
		"ID" : 164,
		"value" : "3252",
		"label" : "Técnicos en documentación sanitaria"
	},
	{
		"ID" : 165,
		"value" : "3253",
		"label" : "Trabajadores comunitarios de la salud"
	},
	{
		"ID" : 166,
		"value" : "3254",
		"label" : "Técnicos en optometría y ópticos"
	},
	{
		"ID" : 167,
		"value" : "3255",
		"label" : "Técnicos y asistentes fisioterapeutas"
	},
	{
		"ID" : 168,
		"value" : "3256",
		"label" : "Practicantes y asistentes médicos"
	},
	{
		"ID" : 169,
		"value" : "3257",
		"label" : "Inspectores de la salud laboral,  medioambiental y afines"
	},
	{
		"ID" : 170,
		"value" : "3258",
		"label" : "Ayudantes de ambulancias"
	},
	{
		"ID" : 171,
		"value" : "3259",
		"label" : "Profesionales de la salud de nivel medio no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 172,
		"value" : "3311",
		"label" : "Agentes de bolsa, cambio y otros servicios financieros"
	},
	{
		"ID" : 173,
		"value" : "3312",
		"label" : "Oficiales de préstamos y créditos"
	},
	{
		"ID" : 174,
		"value" : "3313",
		"label" : "Tenedores de libros"
	},
	{
		"ID" : 175,
		"value" : "3314",
		"label" : "Profesionales de nivel medio de servicios estadísticos, matemáticos y afines"
	},
	{
		"ID" : 176,
		"value" : "3315",
		"label" : "Tasadores"
	},
	{
		"ID" : 177,
		"value" : "3321",
		"label" : "Agentes de seguros"
	},
	{
		"ID" : 178,
		"value" : "3322",
		"label" : "Representantes comerciales"
	},
	{
		"ID" : 179,
		"value" : "3323",
		"label" : "Agentes de compras"
	},
	{
		"ID" : 180,
		"value" : "3324",
		"label" : "Agentes de compras y consignatarios"
	},
	{
		"ID" : 181,
		"value" : "3331",
		"label" : "Declarantes o gestores de aduana"
	},
	{
		"ID" : 182,
		"value" : "3332",
		"label" : "Organizadores de conferencias y eventos"
	},
	{
		"ID" : 183,
		"value" : "3333",
		"label" : "Agentes de empleo y contratistas de mano de obra"
	},
	{
		"ID" : 184,
		"value" : "3334",
		"label" : "Agentes inmobiliarios"
	},
	{
		"ID" : 185,
		"value" : "3339",
		"label" : "Agentes de servicios comerciales no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 186,
		"value" : "3341",
		"label" : "Supervisores de secretaría"
	},
	{
		"ID" : 187,
		"value" : "3342",
		"label" : "Secretarios jurídicos"
	},
	{
		"ID" : 188,
		"value" : "3343",
		"label" : "Secretarios administrativos y ejecutivos"
	},
	{
		"ID" : 189,
		"value" : "3344",
		"label" : "Secretarios médicos"
	},
	{
		"ID" : 190,
		"value" : "3351",
		"label" : "Agentes de aduana e inspectores de fronteras"
	},
	{
		"ID" : 191,
		"value" : "3352",
		"label" : "Agentes de administración tributaria"
	},
	{
		"ID" : 192,
		"value" : "3353",
		"label" : "Agentes de servicios de seguridad social"
	},
	{
		"ID" : 193,
		"value" : "3354",
		"label" : "Agentes de servicios de expedición de licencias y permisos"
	},
	{
		"ID" : 194,
		"value" : "3355",
		"label" : "Inspectores de policía y detectives"
	},
	{
		"ID" : 195,
		"value" : "3359",
		"label" : "Agentes de la administración pública para la aplicacion de la ley y afines no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 196,
		"value" : "3411",
		"label" : "Profesionales de nivel medio del derecho y servicios legales y afines"
	},
	{
		"ID" : 197,
		"value" : "3412",
		"label" : "Trabajadores y asistentes sociales de nivel medio"
	},
	{
		"ID" : 198,
		"value" : "3413",
		"label" : "Auxiliares laicos de las religiones"
	},
	{
		"ID" : 199,
		"value" : "3421",
		"label" : "Atletas y deportistas"
	},
	{
		"ID" : 200,
		"value" : "3422",
		"label" : "Entrenadores, instructores y árbitros de actividades deportivas"
	},
	{
		"ID" : 201,
		"value" : "3423",
		"label" : "Instructores de educación física y actividades recreativas"
	},
	{
		"ID" : 202,
		"value" : "3431",
		"label" : "Fotógrafos"
	},
	{
		"ID" : 203,
		"value" : "3432",
		"label" : "Diseñadores y decoradores de interior"
	},
	{
		"ID" : 204,
		"value" : "3433",
		"label" : "Técnicos en galerías de arte, museos y bibliotecas"
	},
	{
		"ID" : 205,
		"value" : "3434",
		"label" : "Chefs"
	},
	{
		"ID" : 206,
		"value" : "3435",
		"label" : "Otros profesionales de nivel medio en actividades culturales y artísticas"
	},
	{
		"ID" : 207,
		"value" : "3511",
		"label" : "Técnicos en operaciones de tecnología de la información y las comunicaciones"
	},
	{
		"ID" : 208,
		"value" : "3512",
		"label" : "Técnicos en asistencia al usuario de tecnología de la información y las comunicaciones"
	},
	{
		"ID" : 209,
		"value" : "3513",
		"label" : "Técnicos en redes y sistemas de computadores"
	},
	{
		"ID" : 210,
		"value" : "3514",
		"label" : "Técnicos de la Web"
	},
	{
		"ID" : 211,
		"value" : "3521",
		"label" : "Técnicos de radiodifusión y grabación audio visual"
	},
	{
		"ID" : 212,
		"value" : "3522",
		"label" : "Técnicos de ingeniería de las telecomunicaciones"
	},
	{
		"ID" : 213,
		"value" : "4110",
		"label" : "Oficinistas generales"
	},
	{
		"ID" : 214,
		"value" : "4120",
		"label" : "Secretarios (general)"
	},
	{
		"ID" : 215,
		"value" : "4131",
		"label" : "Operadores de máquinas de procesamiento de texto y mecanógrafos"
	},
	{
		"ID" : 216,
		"value" : "4132",
		"label" : "Grabadores de datos"
	},
	{
		"ID" : 217,
		"value" : "4211",
		"label" : "Cajeros de bancos y afines"
	},
	{
		"ID" : 218,
		"value" : "4212",
		"label" : "Receptores de apuestas y afines"
	},
	{
		"ID" : 219,
		"value" : "4213",
		"label" : "Prestamistas"
	},
	{
		"ID" : 220,
		"value" : "4214",
		"label" : "Cobradores y afines"
	},
	{
		"ID" : 221,
		"value" : "4221",
		"label" : "Empleados de agencias de viajes"
	},
	{
		"ID" : 222,
		"value" : "4222",
		"label" : "Empleados de centros de llamadas"
	},
	{
		"ID" : 223,
		"value" : "4223",
		"label" : "Telefonistas"
	},
	{
		"ID" : 224,
		"value" : "4224",
		"label" : "Recepcionistas de hoteles"
	},
	{
		"ID" : 225,
		"value" : "4225",
		"label" : "Empleados de ventanillas de informaciones"
	},
	{
		"ID" : 226,
		"value" : "4226",
		"label" : "Recepcionistas (general)"
	},
	{
		"ID" : 227,
		"value" : "4227",
		"label" : "Entrevistadores de encuestas y de investigaciones de mercados"
	},
	{
		"ID" : 228,
		"value" : "4229",
		"label" : "Empleados de servicios de información al cliente no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 229,
		"value" : "4311",
		"label" : "Empleados de contabilidad y cálculo de costos"
	},
	{
		"ID" : 230,
		"value" : "4312",
		"label" : "Empleados de servicios estadísticos, financieros y de seguros"
	},
	{
		"ID" : 231,
		"value" : "4313",
		"label" : "Empleados encargados de las nóminas"
	},
	{
		"ID" : 232,
		"value" : "4321",
		"label" : "Empleados de control de abastecimientos e inventario"
	},
	{
		"ID" : 233,
		"value" : "4322",
		"label" : "Empleados de servicios de apoyo a la producción"
	},
	{
		"ID" : 234,
		"value" : "4323",
		"label" : "Empleados de servicios de transporte"
	},
	{
		"ID" : 235,
		"value" : "4411",
		"label" : "Empleados de bibliotecas"
	},
	{
		"ID" : 236,
		"value" : "4412",
		"label" : "Empleados de servicios de correos"
	},
	{
		"ID" : 237,
		"value" : "4413",
		"label" : "Codificadores de datos, correctores de pruebas de imprenta y afines"
	},
	{
		"ID" : 238,
		"value" : "4414",
		"label" : "Escribientes públicos y afines"
	},
	{
		"ID" : 239,
		"value" : "4415",
		"label" : "Empleados de archivos"
	},
	{
		"ID" : 240,
		"value" : "4416",
		"label" : "Empleados del servicio de personal"
	},
	{
		"ID" : 241,
		"value" : "4419",
		"label" : "Personal de apoyo administrativo no clasificado bajo otros epígrafes"
	},
	{
		"ID" : 242,
		"value" : "5111",
		"label" : "Auxiliares de servicio de abordo"
	},
	{
		"ID" : 243,
		"value" : "5112",
		"label" : "Revisores y cobradores de los transportes públicos"
	},
	{
		"ID" : 244,
		"value" : "5113",
		"label" : "Guías de turismo"
	},
	{
		"ID" : 245,
		"value" : "5120",
		"label" : "Cocineros"
	},
	{
		"ID" : 246,
		"value" : "5131",
		"label" : "Camareros de mesas"
	},
	{
		"ID" : 247,
		"value" : "5132",
		"label" : "Camareros de barra"
	},
	{
		"ID" : 248,
		"value" : "5141",
		"label" : "Peluqueros"
	},
	{
		"ID" : 249,
		"value" : "5142",
		"label" : "Especialistas en tratamientos de belleza y afines"
	},
	{
		"ID" : 250,
		"value" : "5151",
		"label" : "Supervisores de mantenimiento y limpieza en oficinas, hoteles y otros establecimientos"
	},
	{
		"ID" : 251,
		"value" : "5152",
		"label" : "Ecónomos y mayordomos domésticos "
	},
	{
		"ID" : 252,
		"value" : "5153",
		"label" : "Conserjes"
	},
	{
		"ID" : 253,
		"value" : "5161",
		"label" : "Astrólogos, adivinadores y afines"
	},
	{
		"ID" : 254,
		"value" : "5162",
		"label" : "Acompañantes y ayudantes de cámara"
	},
	{
		"ID" : 255,
		"value" : "5163",
		"label" : "Personal de pompas fúnebres y embalsamadores"
	},
	{
		"ID" : 256,
		"value" : "5164",
		"label" : "Cuidadores de animales"
	},
	{
		"ID" : 257,
		"value" : "5165",
		"label" : "Instructores de autoescuela"
	},
	{
		"ID" : 258,
		"value" : "5169",
		"label" : "Trabajadores de servicios personales no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 259,
		"value" : "5211",
		"label" : "Vendedores de quioscos y de puestos de mercado"
	},
	{
		"ID" : 260,
		"value" : "5212",
		"label" : "Vendedores ambulantes de productos comestibles"
	},
	{
		"ID" : 261,
		"value" : "5221",
		"label" : "Comerciantes de tiendas"
	},
	{
		"ID" : 262,
		"value" : "5222",
		"label" : "Supervisores de tiendas y almacenes"
	},
	{
		"ID" : 263,
		"value" : "5223",
		"label" : "Asistentes de venta de tiendas y almacenes"
	},
	{
		"ID" : 264,
		"value" : "5230",
		"label" : "Cajeros y expendedores de billetes"
	},
	{
		"ID" : 265,
		"value" : "5241",
		"label" : "Modelos de moda, arte y publicidad"
	},
	{
		"ID" : 266,
		"value" : "5242",
		"label" : "Demostradores de tiendas"
	},
	{
		"ID" : 267,
		"value" : "5243",
		"label" : "Vendedores puerta a puerta"
	},
	{
		"ID" : 268,
		"value" : "5244",
		"label" : "Vendedores por teléfono"
	},
	{
		"ID" : 269,
		"value" : "5245",
		"label" : "Expendedores de gasolineras"
	},
	{
		"ID" : 270,
		"value" : "5246",
		"label" : "Vendedores de comidas al mostrador"
	},
	{
		"ID" : 271,
		"value" : "5249",
		"label" : "Vendedores no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 272,
		"value" : "5311",
		"label" : "Cuidadores de niños"
	},
	{
		"ID" : 273,
		"value" : "5312",
		"label" : "Auxiliares de maestros"
	},
	{
		"ID" : 274,
		"value" : "5321",
		"label" : "Trabajadores de los cuidados personales en instituciones"
	},
	{
		"ID" : 275,
		"value" : "5322",
		"label" : "Trabajadores de los cuidados personales a domicilio"
	},
	{
		"ID" : 276,
		"value" : "5329",
		"label" : "Trabajadores de los cuidados personales en servicios de salud no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 394,
		"value" : "8219",
		"label" : "Ensambladores no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 395,
		"value" : "8311",
		"label" : "Maquinistas de locomotoras"
	},
	{
		"ID" : 396,
		"value" : "8312",
		"label" : "Guardafrenos, guardagujas y agentes de maniobras"
	},
	{
		"ID" : 397,
		"value" : "8321",
		"label" : "Conductores de motocicletas"
	},
	{
		"ID" : 398,
		"value" : "8322",
		"label" : "Conductores de automóviles, taxis y camionetas"
	},
	{
		"ID" : 399,
		"value" : "8331",
		"label" : "Conductores de autobuses y tranvías"
	},
	{
		"ID" : 400,
		"value" : "8332",
		"label" : "Conductores de camiones pesados"
	},
	{
		"ID" : 401,
		"value" : "8341",
		"label" : "Operadores de maquinaria agrícola y forestal móvil"
	},
	{
		"ID" : 402,
		"value" : "8342",
		"label" : "Operadores de máquinas de movimiento de tierras y afines"
	},
	{
		"ID" : 403,
		"value" : "8343",
		"label" : "Operadores de grúas, aparatos elevadores y afines"
	},
	{
		"ID" : 404,
		"value" : "8344",
		"label" : "Operadores de autoelevadoras"
	},
	{
		"ID" : 405,
		"value" : "8350",
		"label" : "Marineros de cubierta y afines"
	},
	{
		"ID" : 406,
		"value" : "9111",
		"label" : "Limpiadores y asistentes domésticos"
	},
	{
		"ID" : 407,
		"value" : "9112",
		"label" : "Limpiadores y asistentes de oficinas, hoteles y otros establecimientos"
	},
	{
		"ID" : 408,
		"value" : "9121",
		"label" : "Lavanderos y planchadores manuales"
	},
	{
		"ID" : 409,
		"value" : "9122",
		"label" : "Lavadores de vehículos"
	},
	{
		"ID" : 410,
		"value" : "9123",
		"label" : "Lavadores de ventanas"
	},
	{
		"ID" : 411,
		"value" : "9129",
		"label" : "Otro personal de limpieza"
	},
	{
		"ID" : 412,
		"value" : "9211",
		"label" : "Peones de explotaciones agrícolas"
	},
	{
		"ID" : 413,
		"value" : "9212",
		"label" : "Peones de explotaciones ganaderas"
	},
	{
		"ID" : 414,
		"value" : "9213",
		"label" : "Peones de explotaciones de cultivos mixtos y ganaderos"
	},
	{
		"ID" : 415,
		"value" : "9214",
		"label" : "Peones de jardinería y horticultura"
	},
	{
		"ID" : 416,
		"value" : "9215",
		"label" : "Peones forestales"
	},
	{
		"ID" : 417,
		"value" : "9216",
		"label" : "Peones de pesca y acuicultura"
	},
	{
		"ID" : 418,
		"value" : "9311",
		"label" : "Peones de minas y canteras"
	},
	{
		"ID" : 419,
		"value" : "9312",
		"label" : "Peones de obras públicas y mantenimiento"
	},
	{
		"ID" : 420,
		"value" : "9313",
		"label" : "Peones de la construcción de edificios"
	},
	{
		"ID" : 421,
		"value" : "9321",
		"label" : "Empacadores manuales"
	},
	{
		"ID" : 422,
		"value" : "9329",
		"label" : "Peones de la industria manufacturera no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 423,
		"value" : "9331",
		"label" : "Conductores de vehículos accionados a pedal o a brazo"
	},
	{
		"ID" : 424,
		"value" : "9332",
		"label" : "Conductores de vehículos y máquinas de tracción animal"
	},
	{
		"ID" : 425,
		"value" : "9333",
		"label" : "Peones de carga"
	},
	{
		"ID" : 426,
		"value" : "9334",
		"label" : "Reponedores de estanterías"
	},
	{
		"ID" : 427,
		"value" : "9411",
		"label" : "Cocineros de comidas rápidas"
	},
	{
		"ID" : 428,
		"value" : "9412",
		"label" : "Ayudantes de cocina"
	},
	{
		"ID" : 429,
		"value" : "9510",
		"label" : "Trabajadores ambulantes de servicios y afines"
	},
	{
		"ID" : 430,
		"value" : "9520",
		"label" : "Vendedores ambulantes (excluyendo de comida)"
	},
	{
		"ID" : 431,
		"value" : "9611",
		"label" : "Recolectores de basura y material reciclable"
	},
	{
		"ID" : 432,
		"value" : "9612",
		"label" : "Clasificadores de desechos"
	},
	{
		"ID" : 433,
		"value" : "9613",
		"label" : "Barrenderos y afines"
	},
	{
		"ID" : 434,
		"value" : "9621",
		"label" : "Mensajeros, mandaderos, maleteros y repartidores"
	},
	{
		"ID" : 435,
		"value" : "9622",
		"label" : "Personas que realizan trabajos varios"
	},
	{
		"ID" : 436,
		"value" : "9623",
		"label" : "Recolectores de dinero en aparatos de venta automática y lectores de medidores"
	},
	{
		"ID" : 437,
		"value" : "9624",
		"label" : "Acarreadores de agua y recolectores  de  leña"
	},
	{
		"ID" : 438,
		"value" : "9629",
		"label" : "Ocupaciones elementales no clasificadas bajo otros epígrafes"
	},
	{
		"ID" : 439,
		"value" : "9700",
		"label" : "Estudiante"
	},
	{
		"ID" : 440,
		"value" : "9701",
		"label" : "Jubilado"
	},
	{
		"ID" : 441,
		"value" : "9702",
		"label" : "Desempleado"
	},
	{
		"ID" : 442,
		"value" : "9703",
		"label" : "Profesional Independiente"
	},
	{
		"ID" : 443,
		"value" : "9704",
		"label" : "Comerciante Informal"
	},
	{
		"ID" : 444,
		"value" : "9901",
		"label" : "Otros"
	},
	{
		"ID" : 445,
		"value" : "9902",
		"label" : "Desconocido"
	},
	{
		"ID" : 277,
		"value" : "5411",
		"label" : "Bomberos"
	},
	{
		"ID" : 278,
		"value" : "5412",
		"label" : "Policías"
	},
	{
		"ID" : 279,
		"value" : "5413",
		"label" : "Guardianes de prisión"
	},
	{
		"ID" : 280,
		"value" : "5414",
		"label" : "Guardias de protección"
	},
	{
		"ID" : 281,
		"value" : "5419",
		"label" : "Personal de los servicios de protección no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 282,
		"value" : "6111",
		"label" : "Agricultores y trabajadores calificados de cultivos extensivos"
	},
	{
		"ID" : 283,
		"value" : "6112",
		"label" : "Agricultores y trabajadores calificados de plantaciones de árboles y arbustos"
	},
	{
		"ID" : 284,
		"value" : "6113",
		"label" : "Agricultores y trabajadores calificados de huertas, invernaderos, viveros y jardines"
	},
	{
		"ID" : 285,
		"value" : "6114",
		"label" : "Agricultores y trabajadores calificados de cultivos mixtos"
	},
	{
		"ID" : 286,
		"value" : "6121",
		"label" : "Criadores de ganado"
	},
	{
		"ID" : 287,
		"value" : "6122",
		"label" : "Avicultores y trabajadores calificados de la avicultura"
	},
	{
		"ID" : 288,
		"value" : "6123",
		"label" : "Apicultores y sericultores y trabajadores calificados de la apicultura y la sericultura"
	},
	{
		"ID" : 289,
		"value" : "6129",
		"label" : "Criadores y trabajadores pecuarios calificados de la cría de animales no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 290,
		"value" : "6130",
		"label" : "Productores y trabajadores calificados de explotaciones agropecuarias mixtas cuya producción se destina al mercado"
	},
	{
		"ID" : 291,
		"value" : "6210",
		"label" : "Trabajadores forestales calificados y afines"
	},
	{
		"ID" : 292,
		"value" : "6221",
		"label" : "Trabajadores de explotaciones de acuicultura"
	},
	{
		"ID" : 293,
		"value" : "6222",
		"label" : "Pescadores de agua dulce y en aguas costeras"
	},
	{
		"ID" : 294,
		"value" : "6223",
		"label" : "Pescadores de alta mar"
	},
	{
		"ID" : 295,
		"value" : "6224",
		"label" : "Cazadores y tramperos"
	},
	{
		"ID" : 296,
		"value" : "6310",
		"label" : "Trabajadores agrícolas de subsistencia"
	},
	{
		"ID" : 297,
		"value" : "6320",
		"label" : "Trabajadores pecuarios de subsistencia"
	},
	{
		"ID" : 298,
		"value" : "6330",
		"label" : "Trabajadores agropecuarios de subsistencia"
	},
	{
		"ID" : 299,
		"value" : "6340",
		"label" : "Pescadores, cazadores, tramperos y recolectores de subsistencia"
	},
	{
		"ID" : 300,
		"value" : "7111",
		"label" : "Constructores de casas"
	},
	{
		"ID" : 301,
		"value" : "7112",
		"label" : "Albañiles"
	},
	{
		"ID" : 302,
		"value" : "7113",
		"label" : "Mamposteros, tronzadores, labrantes y grabadores de piedra"
	},
	{
		"ID" : 303,
		"value" : "7114",
		"label" : "Operarios en cemento armado, enfoscadores y afines"
	},
	{
		"ID" : 304,
		"value" : "7115",
		"label" : "Carpinteros de armar y de obra blanca"
	},
	{
		"ID" : 305,
		"value" : "7119",
		"label" : "Oficiales y operarios de la construcción (obra gruesa) y afines no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 306,
		"value" : "7121",
		"label" : "Techadores"
	},
	{
		"ID" : 307,
		"value" : "7122",
		"label" : "Parqueteros y colocadores de suelos"
	},
	{
		"ID" : 308,
		"value" : "7123",
		"label" : "Revocadores"
	},
	{
		"ID" : 309,
		"value" : "7124",
		"label" : "Instaladores de material aislante y de insonorización"
	},
	{
		"ID" : 310,
		"value" : "7125",
		"label" : "Cristaleros"
	},
	{
		"ID" : 311,
		"value" : "7126",
		"label" : "Fontaneros e instaladores de tuberías"
	},
	{
		"ID" : 312,
		"value" : "7127",
		"label" : "Mecánicos-montadores de instalaciones de refrigeración y climatización"
	},
	{
		"ID" : 313,
		"value" : "7131",
		"label" : "Pintores y empapeladores"
	},
	{
		"ID" : 314,
		"value" : "7132",
		"label" : "Barnizadores y afines"
	},
	{
		"ID" : 315,
		"value" : "7133",
		"label" : "Limpiadores de fachadas y deshollinadores"
	},
	{
		"ID" : 316,
		"value" : "7211",
		"label" : "Moldeadores y macheros"
	},
	{
		"ID" : 317,
		"value" : "7212",
		"label" : "Soldadores y oxicortadores"
	},
	{
		"ID" : 318,
		"value" : "7213",
		"label" : "Chapistas y caldereros"
	},
	{
		"ID" : 319,
		"value" : "7214",
		"label" : "Montadores de estructuras metálicas"
	},
	{
		"ID" : 320,
		"value" : "7215",
		"label" : "Aparejadores y empalmadores de cables"
	},
	{
		"ID" : 321,
		"value" : "7221",
		"label" : "Herreros y forjadores"
	},
	{
		"ID" : 322,
		"value" : "7222",
		"label" : "Herramentistas y afines"
	},
	{
		"ID" : 323,
		"value" : "7223",
		"label" : "Reguladores y operadores de máquinas herramientas"
	},
	{
		"ID" : 324,
		"value" : "7224",
		"label" : "Pulidores de metales y afiladores de herramientas"
	},
	{
		"ID" : 325,
		"value" : "7231",
		"label" : "Mecánicos y reparadores de vehículos de motor"
	},
	{
		"ID" : 326,
		"value" : "7232",
		"label" : "Mecánicos y reparadores de motores de avión"
	},
	{
		"ID" : 327,
		"value" : "7233",
		"label" : "Mecánicos y reparadores de máquinas agrícolas e industriales"
	},
	{
		"ID" : 328,
		"value" : "7234",
		"label" : "Reparadores de bicicletas y afines"
	},
	{
		"ID" : 329,
		"value" : "7311",
		"label" : "Mecánicos y reparadores de instrumentos de precisión"
	},
	{
		"ID" : 330,
		"value" : "7312",
		"label" : "Fabricantes y afinadores de instrumentos musicales"
	},
	{
		"ID" : 331,
		"value" : "7313",
		"label" : "Joyeros, orfebres y plateros"
	},
	{
		"ID" : 332,
		"value" : "7314",
		"label" : "Alfareros y afines (barro, arcilla y abrasivos)"
	},
	{
		"ID" : 333,
		"value" : "7315",
		"label" : "Sopladores, modeladores, laminadores, cortadores y pulidores de vidrio"
	},
	{
		"ID" : 334,
		"value" : "7316",
		"label" : "Redactores de carteles, pintores decorativos y grabadores"
	},
	{
		"ID" : 335,
		"value" : "7317",
		"label" : "Artesanos en madera, cestería y materiales similares"
	},
	{
		"ID" : 336,
		"value" : "7318",
		"label" : "Artesanos de los tejidos, el cuero y materiales similares"
	},
	{
		"ID" : 337,
		"value" : "7319",
		"label" : "Artesanos no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 338,
		"value" : "7321",
		"label" : "Cajistas, tipógrafos y afines"
	},
	{
		"ID" : 339,
		"value" : "7322",
		"label" : "Impresores"
	},
	{
		"ID" : 340,
		"value" : "7323",
		"label" : "Encuadernadores y afines"
	},
	{
		"ID" : 341,
		"value" : "7411",
		"label" : "Electricistas de obras y afines"
	},
	{
		"ID" : 342,
		"value" : "7412",
		"label" : "Mecánicos y ajustadores electricistas"
	},
	{
		"ID" : 343,
		"value" : "7413",
		"label" : "Instaladores y reparadores de líneas eléctricas "
	},
	{
		"ID" : 344,
		"value" : "7421",
		"label" : "Mecánicos y reparadores en electrónica"
	},
	{
		"ID" : 345,
		"value" : "7422",
		"label" : "Instaladores y reparadores en tecnología de la información y las comunicaciones"
	},
	{
		"ID" : 346,
		"value" : "7511",
		"label" : "Carniceros, pescaderos y afines"
	},
	{
		"ID" : 347,
		"value" : "7512",
		"label" : "Panaderos, pasteleros y confiteros"
	},
	{
		"ID" : 348,
		"value" : "7513",
		"label" : "Operarios de la elaboración de productos lácteos"
	},
	{
		"ID" : 349,
		"value" : "7514",
		"label" : "Operarios de la conservación de frutas, legumbres, verduras y afines"
	},
	{
		"ID" : 350,
		"value" : "7515",
		"label" : "Catadores y clasificadores de alimentos y bebidas"
	},
	{
		"ID" : 351,
		"value" : "7516",
		"label" : "Preparadores y elaboradores de tabaco y sus productos"
	},
	{
		"ID" : 352,
		"value" : "7521",
		"label" : "Operarios del tratamiento de la madera"
	},
	{
		"ID" : 353,
		"value" : "7522",
		"label" : "Ebanistas y afines"
	},
	{
		"ID" : 354,
		"value" : "7523",
		"label" : "Reguladores y operadores de máquinas de labrar madera"
	},
	{
		"ID" : 355,
		"value" : "7531",
		"label" : "Sastres, modistos, peleteros y sombrereros"
	},
	{
		"ID" : 356,
		"value" : "7532",
		"label" : "Patronistas y cortadores de tela y afines"
	},
	{
		"ID" : 357,
		"value" : "7533",
		"label" : "Costureros, bordadores y afines"
	},
	{
		"ID" : 358,
		"value" : "7534",
		"label" : "Tapiceros, colchoneros y afines"
	},
	{
		"ID" : 359,
		"value" : "7535",
		"label" : "Apelambradores, pellejeros y curtidores"
	},
	{
		"ID" : 360,
		"value" : "7536",
		"label" : "Zapateros y afines"
	},
	{
		"ID" : 361,
		"value" : "7541",
		"label" : "Buzos"
	},
	{
		"ID" : 362,
		"value" : "7542",
		"label" : "Dinamiteros y pegadores"
	},
	{
		"ID" : 363,
		"value" : "7543",
		"label" : "Clasificadores y probadores de productos (excluyendo alimentos y bebidas)"
	},
	{
		"ID" : 364,
		"value" : "7544",
		"label" : "Fumigadores y otros controladores de plagas y malas hierbas"
	},
	{
		"ID" : 365,
		"value" : "7549",
		"label" : "Oficiales, operarios y artesanos de artes mecánicas y de otros oficios no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 366,
		"value" : "8111",
		"label" : "Mineros y operadores de instalaciones mineras"
	},
	{
		"ID" : 367,
		"value" : "8112",
		"label" : "Operadores de instalaciones de procesamiento de minerales y rocas"
	},
	{
		"ID" : 368,
		"value" : "8113",
		"label" : "Perforadores y sondistas de pozos y afines"
	},
	{
		"ID" : 369,
		"value" : "8114",
		"label" : "Operadores de máquinas para fabricar cemento y otros productos minerales"
	},
	{
		"ID" : 370,
		"value" : "8121",
		"label" : "Operadores de instalaciones de procesamiento de metales"
	},
	{
		"ID" : 371,
		"value" : "8122",
		"label" : "Operadores de máquinas pulidoras, galvanizadoras y recubridoras de metales "
	},
	{
		"ID" : 372,
		"value" : "8131",
		"label" : "Operadores de plantas y máquinas de productos químicos"
	},
	{
		"ID" : 373,
		"value" : "8132",
		"label" : "Operadores de máquinas para fabricar productos fotográficos"
	},
	{
		"ID" : 374,
		"value" : "8141",
		"label" : "Operadores de máquinas para fabricar productos de caucho"
	},
	{
		"ID" : 375,
		"value" : "8142",
		"label" : "Operadores de máquinas para fabricar productos de material plástico"
	},
	{
		"ID" : 376,
		"value" : "8143",
		"label" : "Operadores de máquinas para fabricar productos de papel"
	},
	{
		"ID" : 377,
		"value" : "8151",
		"label" : "Operadores de máquinas de preparación de fibras, hilado y devanado"
	},
	{
		"ID" : 378,
		"value" : "8152",
		"label" : "Operadores de telares y otras máquinas tejedoras"
	},
	{
		"ID" : 379,
		"value" : "8153",
		"label" : "Operadores de máquinas de coser"
	},
	{
		"ID" : 380,
		"value" : "8154",
		"label" : "Operadores de máquinas de blanqueamiento, teñido y limpieza de tejidos"
	},
	{
		"ID" : 381,
		"value" : "8155",
		"label" : "Operadores de máquinas de tratamiento de pieles y cueros"
	},
	{
		"ID" : 382,
		"value" : "8156",
		"label" : "Operadores de máquinas para la fabricación de calzado y afines"
	},
	{
		"ID" : 383,
		"value" : "8157",
		"label" : "Operadores de máquinas lavarropas"
	},
	{
		"ID" : 384,
		"value" : "8159",
		"label" : "Operadores de máquinas para fabricar productos textiles y artículos de piel y cuero no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 385,
		"value" : "8160",
		"label" : "Operadores de máquinas para elaborar alimentos y productos afines"
	},
	{
		"ID" : 386,
		"value" : "8171",
		"label" : "Operadores de instalaciones para la preparación de pasta para papel y papel"
	},
	{
		"ID" : 387,
		"value" : "8172",
		"label" : "Operadores de instalaciones de procesamiento de la madera"
	},
	{
		"ID" : 388,
		"value" : "8181",
		"label" : "Operadores de instalaciones de vidriería y cerámica"
	},
	{
		"ID" : 389,
		"value" : "8182",
		"label" : "Operadores de máquinas de vapor y calderas"
	},
	{
		"ID" : 390,
		"value" : "8183",
		"label" : "Operadores de máquinas de embalaje, embotellamiento y etiquetado "
	},
	{
		"ID" : 391,
		"value" : "8189",
		"label" : "Operadores de máquinas y de instalaciones fijas no clasificados bajo otros epígrafes"
	},
	{
		"ID" : 392,
		"value" : "8211",
		"label" : "Ensambladores de maquinaria mecánica"
	},
	{
		"ID" : 393,
		"value" : "8212",
		"label" : "Ensambladores de equipos eléctricos y electrónicos"
	}
]

export const estadosFiscales = [	
      {
        value: "FC500",
        label: "Consumidor final",
      },
      {
        value: "FC550",
        label: "Crédito fiscal",
      }
]

export const gestionesL0 = [
  { key: "1", label: "Inconvenientes en la atención" },
  { key: "2", label: "Gestiones de reclamo" },
  { key: "3", label: "Solicitar históricos" },
  { key: "4", label: "Fallas con el servicio eléctrico" },
];

export const gestionesL1 = [
  {
    key: "ZO153",
    value: "Mala atención en oficinas comerciales",
    parent: 1,
    is_visible: true,
  },
  {
    key: "ZO154",
    value: "Mala atención en Call Center",
    parent: 1,
    is_visible: true,
  },
  { key: "ZO214", value: "No recibe su factura", parent: 1, is_visible: false },
  {
    key: "ZO011",
    value: "Cobro alto en mi factura",
    parent: 2,
    is_visible: false,
  },
  {
    key: "ZO476",
    value: "Histórico de alcaldia",
    parent: 3,
    is_visible: false,
  },
  {
    key: "ZO477",
    value: "Histórico de facturación",
    parent: 3,
    is_visible: false,
  },
  {
    key: "ZO400",
    value: "Zona sin servicio",
    parent: 4,
    is_visible: false,
  },
  {
    key: "ZO443",
    value: "Atraso en la reconexión del servicio",
    parent: 4,
    is_visible: false,
  },
];

//   {
//     key: "ZO400",
//     value: "Zona sin servicio",
//     parent: 4,
//     is_visible: false,
//   },


export const peligrosList = [
  {
    key: "SE001",
    value: "Todo correcto",
  },
  {
    key: "SE002",
    value: "Postes caídos",
  },
  {
    key: "SE003",
    value: "Líneas en llamas",
  },
  {
    key: "SE004",
    value: "Línea rota",
  },
  {
    key: "SE005",
    value: "Líneas en el suelo",
  },
  {
    key: "SE006",
    value: "Lluvias",
  },
  {
    key: "SE007",
    value: "Otros",
  },
];

export const attachmentsList = [
	{
        id:"1",
        name:'Papeleta del electricista',
        required: true,
        public_url:"",
        attached:false
    },
    {
        id:"2",
        name:'Carnet del electricista',
        required: true,
        public_url:"",
        attached:false
    },{
        id:"3",
        name:'Certificación OIA',
        required: false,
        public_url:"",
        attached:false
    },
    {
        id:"4",
        name:'Croquis de ubicación',
        required: true,
        public_url:"",
        attached:false
    },
    {
        id:"5",
        name:'Escritura certificada por el CNR',
        required: true,
        public_url:"",
        attached:false,
    },
    {
        id:"6",
        name:'Archivo de DUI (Frente-Dorso)',
        required: true,
        public_url:"",
        attached:false
    },
    {
        id:"7",
        name:'Archivo de NIT (Frente-Dorso)',
        required: true,
        public_url:"",
        attached:false
    },
    {
        id:"8",
        name:'Registro de contribuyente (Si requiere CCF)',
        required: false,
        public_url:"",
        attached:false
    },
    {
        id:"9",
        name:'Conoce a tu cliente',
        required: true,
        public_url:"",
        attached:false
    },
    {
        id:"10",
        name:'DUI Propietario (Para arrendatarios)',
        required: true,
        public_url:"",
        attached:false
    },
    {
        id:"11",
        name:'NIT Propietario (Para arrendatarios)',
        required: true,
        public_url:"",
        attached:false
    },
    {
        id:"12",
        name:'Autorización escrita y notariada del propietario (Para arrendatarios)',
        required: true,
        public_url:"",
        attached:false
    },{
        id:"13",
        name:'Copia de credencial (Persona jurídica)',
        required: false,
        public_url:"",
        attached:false
    },
    {
        id:"14",
        name:'Escritura de constitución (Persona jurídica)',
        required: true,
        public_url:"",
        attached:false
    }, 
]

export const servicesList = [
	{
		"title":"Atención al cliente",
		"id":1
	},
	{
		"title":"Pago de servicios AES (CAESS, CLESA, EEO, DEUSEM)",
		"id":2
	},
	{
		"title":"Atención a grandes clientes",
		"id":3
	},
	{
		"title":"Servicio de autogestión",
		"id":4
	},
	{
		"title":"Atención de irregularidades",
		"id":5
	},
	{
		"title":"Otros",
		"id":6
	},
]

export const attachmentsListFEL = [
	{
        id:"1",
        name:'Adjunta foto del documento (frente)',
        required: false,
        public_url:"",
        attached:false
    },
	{
        id:"2",
        name:'Adjunta foto del documento (dorso)',
        required: false,
        public_url:"",
        attached:false
    },
]


export const tiposPersonaFEL = [
	{
		"title":"Persona natural",
		"id":"LV001"
	},
	{
		"title":"Persona jurídica",
		"id":"LV002"
	},
]

const config = {
  layout: LAYOUT_CONST.VERTICAL_LAYOUT, // vertical, horizontal
  drawerType: LAYOUT_CONST.DEFAULT_DRAWER, // default, mini-drawer
  fontFamily: `'Arial', sans-serif`,
  borderRadius: 4,
  outlinedFilled: true,
  // navType: 'dark', // light, dark
  navType: paletteMode, // light, dark auto by day hours
  presetColor: "theme6", // default, theme1, theme2, theme3, theme4, theme5, theme6
  locale: "es", // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  rtlLayout: false,
  container: true,
};

export default config;
